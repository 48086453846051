import React from 'react'
import './header.scss'
import Logo from '../../../media/parkspring-logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const headerparkspring = () => {

    const waheader = () => {
        const whatsappLink =
          "https://api.whatsapp.com/send/?phone=6281283139739&text=Halo%20Daniel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Park%20Spring%20(Info)%20https://parkspring-gading.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    }
  return (
    <div className='container-headerparkspring'>
        <div className='logoparkspring'>
            <img className='gambarparkspring' src={Logo} alt="logo-parkspring" />
        </div>
        <div className='container-buttonwa'>
            <button className= 'button' onClick={waheader}>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                </button>
        </div>
    </div>
  )
}

export default headerparkspring;