import Hiro1 from '../../../../media/Park Spring/Hiro/Hiro (1).png'
import Hiro3 from '../../../../media/Park Spring/Hiro/Hiro (3).png'
import Hiro4 from '../../../../media/Park Spring/Hiro/Hiro (4).png'
import Hiro5 from '../../../../media/Park Spring/Hiro/Hiro (5).png'
import Hiro6 from '../../../../media/Park Spring/Hiro/Hiro (6).png'
import Hiro7 from '../../../../media/Park Spring/Hiro/Hiro (7).png'
import Hiro8 from '../../../../media/Park Spring/Hiro/Hiro (8).png'


const Hiroimages =[
Hiro1,Hiro3,Hiro4,Hiro5,Hiro6,Hiro7,Hiro8
]

export default Hiroimages;