import React from "react";
import "./about.scss"
import logo from "../../../media/parkspring-logo.png"

const aboutparkspring = () => {
    return (
        <div className="container-aboutparkspring">
                <div className="logo-aboutparkspring">
                    <img className="gambar-logoparkspring" src={logo} alt="logo-parkspring" />
                </div>
            <div className="container-deskripsiparkspring">
                <h1>Love Your Living</h1>
                <div className="deskripsi-aboutparkspring">
                Parkspring Gading merupakan Proyek Perumahan yang dikembangkan oleh Karsindo Group. Park Spring Gading adalah hunian modern yang mengusung konsep Japanese Tropical yang di design oleh Arsitek Ternama Stanley Winaryo. Park Spring Gading merupakan sebuah cluster rumah yang terletak di Kelapa Gading Barat, Jakarta Utara. Park Spring Gading menawarkan beragam fasilitas untuk mendukung gaya hidup modern dan seimbang dimana Park Spring Gading menawarkan fasilitas seperti Jogging Track, Yoga Spot, Children Playground, Outdoor Gym. Serta keamanan dan kenyamanan. Park Spring Gading sudah terjamin sangat baik karena dilengkapi oleh One Gate System.
                </div>
            </div>
        </div>
    );
};

export default aboutparkspring;