import Villa1 from '../../../../media/Park Spring/Villa/Villa (1).png'
import Villa2 from '../../../../media/Park Spring/Villa/Villa (2).png'
import Villa3 from '../../../../media/Park Spring/Villa/Villa (3).png'
import Villa4 from '../../../../media/Park Spring/Villa/Villa (4).png'
import Villa5 from '../../../../media/Park Spring/Villa/Villa (5).png'
import Villa6 from '../../../../media/Park Spring/Villa/Villa (6).png'
import Villa7 from '../../../../media/Park Spring/Villa/Villa (7).png'
import Villa8 from '../../../../media/Park Spring/Villa/Villa (8).png'
import Villa9 from '../../../../media/Park Spring/Villa/Villa (9).png'
import Villa10 from '../../../../media/Park Spring/Villa/Villa (10).png'
import Villa11 from '../../../../media/Park Spring/Villa/Villa (11).png'
import Villa12 from '../../../../media/Park Spring/Villa/Villa (12).png'
import Villa13 from '../../../../media/Park Spring/Villa/Villa (13).png'
import Villa14 from '../../../../media/Park Spring/Villa/Villa (14).png'

const Villaimages =[
Villa1,Villa2,Villa3,Villa4,Villa5,Villa6,Villa7,Villa8,Villa9,Villa10,Villa11,Villa12,Villa13,Villa14
]

export default Villaimages;