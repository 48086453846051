import Grand1 from '../../../../media/Park Spring/Grand/Grand (1).png'
import Grand2 from '../../../../media/Park Spring/Grand/Grand (2).png'
import Grand3 from '../../../../media/Park Spring/Grand/Grand (3).png'
import Grand4 from '../../../../media/Park Spring/Grand/Grand (4).png'
import Grand5 from '../../../../media/Park Spring/Grand/Grand (5).png'


const Grandimages =[
Grand1,Grand2,Grand3,Grand4,Grand5
]

export default Grandimages;