import React from "react";
import "./promo.scss";
import penawaran from "../../../media/design hardsel 34 (7).png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";

const Penawaran = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281283139739&text=Halo%20Daniel,%20Saya%20bisa%20minta%20detail%20Promo%20terbaru%20produk%20Park%20Spring%20(Promo)%20https://parkspring-gading.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="judulpromo">
      <div id="promo" className="judul">
        <h1>OUR PROMO</h1>
      </div>
      <div id="promo" className=" containerPenawaran">
        <div className="containercontent">
          <div className="pointpenawaran">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "white" }}>&nbsp;&nbsp;Siap Huni</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "white" }}>
                &nbsp;&nbsp;Subsidi DP
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "white" }}>&nbsp;&nbsp;Free Biaya-biaya</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "white" }}>&nbsp;&nbsp;Subsidi Biaya Asuransi</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "white" }}>&nbsp;&nbsp;Free Smarthome System</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{ color: "white" }}>&nbsp;&nbsp;Cicilan Start 8 Juta/Bulan</span>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            Learn More
          </button>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={penawaran} />
        </div>
      </div>
    </div>
  );
};

export default Penawaran;