import Fasilitas1 from '../../../media/fasilitas Park Spring/1.png'
import Fasilitas2 from '../../../media/fasilitas Park Spring/2.png'
import Fasilitas3 from '../../../media/fasilitas Park Spring/3.png'
import Fasilitas4 from '../../../media/fasilitas Park Spring/4.png'
import Fasilitas5 from '../../../media/fasilitas Park Spring/5.png'
import Fasilitas6 from '../../../media/fasilitas Park Spring/6.png'
import Fasilitas7 from '../../../media/fasilitas Park Spring/7.png'
import Fasilitas8 from '../../../media/fasilitas Park Spring/8.png'




const fasilitasimages =[
    Fasilitas1,Fasilitas2,Fasilitas3,Fasilitas4,Fasilitas5,Fasilitas6,Fasilitas7,Fasilitas8
]

export default fasilitasimages;