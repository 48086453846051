import React from 'react'
import "./lokasi.scss"
import lokasiimages from "../../../media/Maps Park Spring.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";


const lokasi = () => {
    const handleConsultationClick = () => {
        const whatsappLink =
          "https://api.whatsapp.com/send/?phone=6281283139739&text=Halo%20Daniel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Park%20Spring%20(Loaksi)%20https://parkspring-gading.com/&type=phone_number&app_absent=0";
        window.location.href = whatsappLink;
    }
  return (
    <div id='lokasi' className="container-lokasi">
        <div className="container-judul">
          <div className='a'>
            <div className="judul-lokasi">
                <h1>Lokasi </h1>
                <h2>Park Spring</h2>
                <h3>Gading</h3>
                <div className='deskripsi'> 
                <h4>Lokasi yang ideal</h4>
                <h5>untuk tinggal dan berbisnis</h5>
                <h6>dengan banyak infrastruktur</h6>
                </div>
            </div>
            </div>
            <div className="button-penawaran"><button className="buttonpenawaran" onClick={handleConsultationClick}>
                     Learn More
                </button>
          </div>
        </div>
        
        <div id='lokasi' className="lokasi">
             <img className="lokasi-parkspring" src={lokasiimages} alt="hardsell-summarecon-serpong" />
        </div>
    </div>
  )
}

export default lokasi;