import React from "react";
import "./Produk1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Hiroimages from "./Hiro.js";
import Villaimages from "./Villa.js";
import Grandimages from "./Grand.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { faShower } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


  const Hirowa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281283139739&text=Halo%20Daniel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Park%20Spring%20(Tipe%20Hiro)%20https://parkspring-gading.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  }
  const Villawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281283139739&text=Halo%20Daniel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Park%20Spring%20(Tipe%20Villa)%20https://parkspring-gading.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  }
  const Grandwa = () => {  
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281283139739&text=Halo%20Daniel,%20Saya%20bisa%20minta%20detail%20Promo,%20Katalog,%20Simulasi,%20terbaru%20produk%20Park%20Spring%20(Tipe%20Grand)%20https://parkspring-gading.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  }

  return (
    <div className="container">
      <div className="containercard1">
        <div className="card">
          <Slider {...settings}>
            {Hiroimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Hiro</div>
              <span>Tanpa DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">8</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#0f1c3f' icon={faArrowsUpDownLeftRight} />
            <span>  60m²</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faHouse} />
            <span>  74m²</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faShower} />
            <span> 2</span>
          </div>
          <div className="containerwhatsapp">
          <button onClick={Hirowa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Villaimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Villa</div>
              <span>Tanpa DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">10</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#0f1c3f' icon={faArrowsUpDownLeftRight} />
            <span> : 60m²</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faHouse} />
            <span> : 91m²</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faBed} />
            <span>: 4</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faShower} />
            <span>: 4</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Villawa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
        <Slider {...settings}>
            {Grandimages.map((image, index) => (
              <img
                className="imagecard"
                key={index}
                src={image}
              />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Grand</div>
              <span>Tanpa DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">16</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color= '#0f1c3f' icon={faArrowsUpDownLeftRight} />
            <span> : 80m²</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faHouse} />
            <span> : 111m²</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faBed} />
            <span>: 3+1</span>
            <FontAwesomeIcon color= '#0f1c3f' icon={faShower} />
            <span>: 3+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Grandwa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
